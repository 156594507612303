import {createWebHistory,createRouter} from 'vue-router';
import routes from "@/router/routes";

const router = createRouter({
	history:createWebHistory(),
	routes,
	mode:'history',
	scrollBehavior(to,from,savedPosition){
		if(savedPosition){
			return savedPosition
		}else{
			return {top:0,left:0}
		}
	}
})

router.beforeEach((to,from,next) => {
	document.title = to.meta.title ? to.meta.title : '数农大师'
	next()
})

export default router;