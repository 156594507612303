export default [
	{
		path: "/",
		name: "index",
		component:() => import("@/views/pages/index/index.vue"),
		meta: {
			title: "首页"
		}
	},
	// {
	// 	path: "/download",
	// 	name: "download",
	// 	component:() => import("@/views/pages/download/index.vue"),
	// 	meta: {
	// 		title: "客户端下载"
	// 	}
	// }
]