import { createApp } from 'vue'
import App from './App.vue'
import router from "@/router";

let app = createApp(App);

// 引用字体
import '@/assets/fonts/SourceHanSansSC/sourcehansanssc-font.css';
// 引入全局样式
import '@/assets/scss/style.css'

app
	.use(router)
	.mount('#app');
